import "core-js/modules/es.error.cause.js";
function _newArrowCheck(n, r) { if (n !== r) throw new TypeError("Cannot instantiate an arrow function"); }
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _this = this;
  var _component_van_config_provider = _resolveComponent("van-config-provider");
  return _openBlock(), _createBlock(_component_van_config_provider, {
    class: _normalizeClass(["select-none overflow-auto", {
      dark: $setup.isDark,
      'h-full': _ctx.$route.name === 'department'
    }]),
    theme: $setup.isDark ? 'dark' : 'light'
  }, {
    default: _withCtx(function () {
      _newArrowCheck(this, _this);
      return [_renderSlot(_ctx.$slots, "default")];
    }.bind(this)),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["class", "theme"]);
}