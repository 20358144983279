import "core-js/modules/es.object.assign.js";
import { useDark } from '@vueuse/core';
import { useRoute } from 'vue-router';
import { usePageConfig } from '@eaphone/logic';
import { useTitle } from '@eaphone/plugins/hook.js';
var __default__ = {};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'theme',
  setup: function setup(__props, _ref) {
    var __expose = _ref.expose;
    __expose();
    var route = useRoute();
    usePageConfig(route);
    useTitle(route);
    var isDark = useDark();
    var __returned__ = {
      route: route,
      isDark: isDark,
      get useDark() {
        return useDark;
      },
      get useRoute() {
        return useRoute;
      },
      get usePageConfig() {
        return usePageConfig;
      },
      get useTitle() {
        return useTitle;
      }
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
});